import { useRef, useEffect } from 'react';
import { useStore } from '@@/store';
import { useLottie } from '@diezhi/yesio';
import lottiePlayMusic from '@@/animation/lottiePlayMusic/data.json';
import styles from './style.module.scss';

const PlayBtn = ({
  lottie,
  name, // 音频 name
  className,
}) => {
  const ref = useRef();
  const {
    store: {
      sounds: [soundsAction, soundsStatus],
    },
  } = useStore();
  const [{ play, stop }] = useLottie(ref, lottie, {
    autoplay: false,
  });
  const isPlaying = soundsStatus[name];

  const onSwitchPlay = () => {
    if (isPlaying) {
      soundsAction.pause(name, 'stop');
    } else {
      soundsAction.play(name);
    }
  };

  useEffect(() => {
    if (isPlaying) {
      play();
    } else {
      stop();
    }
  }, [isPlaying, play, stop]);

  return <div ref={ref} className={`${styles.container} ${className}`} onClick={onSwitchPlay} />;
};

PlayBtn.defaultProps = {
  lottie: lottiePlayMusic,
  className: '',
};

export default PlayBtn;
