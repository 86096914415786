const assets = [];
assets.push([require("./images/img_0.png").default ? require("./images/img_0.png").default: require("./images/img_0.png"), 0]);
assets.push([require("./images/img_1.png").default ? require("./images/img_1.png").default: require("./images/img_1.png"), 1]);
const data = {
  "v": "5.7.14",
  "fr": 30,
  "ip": 0,
  "op": 105,
  "w": 90,
  "h": 90,
  "nm": "合成 1",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 17,
      "h": 23,
      "u": "",
      "p": "img_0.png",
      "e": 0
    },
    {
      "id": "image_1",
      "w": 115,
      "h": 115,
      "u": "",
      "p": "img_1.png",
      "e": 0
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 2,
      "nm": "组 437.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "t": 106,
              "s": [
                360
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            45.5,
            45.75,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            10.5,
            11.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 20,
      "ty": 2,
      "nm": "播放 拷贝.png",
      "cl": "png",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            49,
            50,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            57.5,
            57.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}

assets.forEach((asset) => {
  const path = typeof asset[0] === 'string' ? asset[0] : asset[0].src;
  const index = asset[1];
  data.assets[index].p = path;
});
module.exports = data;