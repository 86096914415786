const assets = [];
assets.push([require("./images/img_0.jpg").default ? require("./images/img_0.jpg").default: require("./images/img_0.jpg"), 0]);
assets.push([require("./images/img_1.png").default ? require("./images/img_1.png").default: require("./images/img_1.png"), 1]);
const data = {
  "v": "5.7.14",
  "fr": 30,
  "ip": 0,
  "op": 60,
  "w": 90,
  "h": 90,
  "nm": "合成 1",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 40,
      "h": 29,
      "u": "",
      "p": "img_0.jpg",
      "e": 0
    },
    {
      "id": "image_1",
      "w": 115,
      "h": 115,
      "u": "",
      "p": "img_1.png",
      "e": 0
    },
    {
      "id": "comp_0",
      "nm": "合成 2",
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "形状图层 1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                90.25,
                75.625,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                19.25,
                0.625,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 0,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 15,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 30,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 45,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "t": 60,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          19.25,
                          -2.5
                        ],
                        [
                          19.25,
                          3.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "路径 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.427451010311,
                      0.603921568627,
                      0.937254961799,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2.5,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "描边 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "形状 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 61,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "形状图层 13",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                85.375,
                75.625,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                19.25,
                0.625,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": -53,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": -38,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": -23,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": -8,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "t": 7,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          19.25,
                          -2.5
                        ],
                        [
                          19.25,
                          3.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "路径 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.427451010311,
                      0.603921568627,
                      0.937254961799,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2.5,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "描边 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "形状 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 8,
          "st": -53,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          "nm": "形状图层 2",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                85.375,
                75.625,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                19.25,
                0.625,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 8,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 23,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 38,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 53,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "t": 68,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          19.25,
                          -2.5
                        ],
                        [
                          19.25,
                          3.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "路径 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.427451010311,
                      0.603921568627,
                      0.937254961799,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2.5,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "描边 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "形状 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 8,
          "op": 61,
          "st": 8,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          "nm": "形状图层 12",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                80.125,
                75.625,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                19.25,
                0.625,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": -45,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": -30,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": -15,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 0,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "t": 15,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          19.25,
                          -2.5
                        ],
                        [
                          19.25,
                          3.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "路径 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.427451010311,
                      0.603921568627,
                      0.937254961799,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2.5,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "描边 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "形状 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 16,
          "st": -45,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          "nm": "形状图层 3",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                80.125,
                75.625,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                19.25,
                0.625,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 16,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 31,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 46,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 61,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "t": 76,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          19.25,
                          -2.5
                        ],
                        [
                          19.25,
                          3.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "路径 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.427451010311,
                      0.603921568627,
                      0.937254961799,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2.5,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "描边 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "形状 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 16,
          "op": 61,
          "st": 16,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 4,
          "nm": "形状图层 11",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                76,
                75.625,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                19.25,
                0.625,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": -38,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": -23,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": -8,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 7,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "t": 22,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          19.25,
                          -2.5
                        ],
                        [
                          19.25,
                          3.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "路径 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.427451010311,
                      0.603921568627,
                      0.937254961799,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2.5,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "描边 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "形状 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 23,
          "st": -38,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 4,
          "nm": "形状图层 4",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                76,
                75.625,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                19.25,
                0.625,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 23,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 38,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 53,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 68,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "t": 83,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          19.25,
                          -2.5
                        ],
                        [
                          19.25,
                          3.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "路径 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.427451010311,
                      0.603921568627,
                      0.937254961799,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2.5,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "描边 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "形状 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 23,
          "op": 61,
          "st": 23,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 4,
          "nm": "形状图层 10",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                71.5,
                75.625,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                19.25,
                0.625,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": -29,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": -14,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 1,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 16,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "t": 31,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          19.25,
                          -2.5
                        ],
                        [
                          19.25,
                          3.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "路径 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.427451010311,
                      0.603921568627,
                      0.937254961799,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2.5,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "描边 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "形状 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 32,
          "st": -29,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 9,
          "ty": 4,
          "nm": "形状图层 5",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                71.5,
                75.625,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                19.25,
                0.625,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 32,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 47,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 62,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 77,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "t": 92,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          19.25,
                          -2.5
                        ],
                        [
                          19.25,
                          3.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "路径 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.427451010311,
                      0.603921568627,
                      0.937254961799,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2.5,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "描边 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "形状 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 32,
          "op": 61,
          "st": 32,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 10,
          "ty": 4,
          "nm": "形状图层 9",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                67,
                75.625,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                19.25,
                0.625,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": -22,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": -7,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 8,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 23,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "t": 38,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          19.25,
                          -2.5
                        ],
                        [
                          19.25,
                          3.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "路径 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.427451010311,
                      0.603921568627,
                      0.937254961799,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2.5,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "描边 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "形状 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 39,
          "st": -22,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 11,
          "ty": 4,
          "nm": "形状图层 6",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                67,
                75.625,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                19.25,
                0.625,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 39,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 54,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 69,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 84,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "t": 99,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          19.25,
                          -2.5
                        ],
                        [
                          19.25,
                          3.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "路径 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.427451010311,
                      0.603921568627,
                      0.937254961799,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2.5,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "描边 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "形状 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 39,
          "op": 61,
          "st": 39,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 12,
          "ty": 4,
          "nm": "形状图层 8",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                62.25,
                75.625,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                19.25,
                0.625,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": -14,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 1,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 16,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 31,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "t": 46,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          19.25,
                          -2.5
                        ],
                        [
                          19.25,
                          3.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "路径 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.427451010311,
                      0.603921568627,
                      0.937254961799,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2.5,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "描边 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "形状 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 47,
          "st": -14,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 13,
          "ty": 4,
          "nm": "形状图层 7",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                62.25,
                75.625,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                19.25,
                0.625,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 47,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 62,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 77,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 92,
                  "s": [
                    100,
                    263,
                    100
                  ]
                },
                {
                  "t": 107,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          19.25,
                          -2.5
                        ],
                        [
                          19.25,
                          3.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "路径 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.427451010311,
                      0.603921568627,
                      0.937254961799,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2.5,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "描边 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "形状 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 47,
          "op": 61,
          "st": 47,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      "nm": "合成 2",
      "td": 1,
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            45,
            45,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            75,
            75,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 150,
      "h": 150,
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 2,
      "nm": "组 438.png",
      "cl": "png",
      "tt": 1,
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            45,
            45,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            20,
            14.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 19,
      "ty": 2,
      "nm": "播放 拷贝.png",
      "cl": "png",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            49,
            50,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            57.5,
            57.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}

assets.forEach((asset) => {
  const path = typeof asset[0] === 'string' ? asset[0] : asset[0].src;
  const index = asset[1];
  data.assets[index].p = path;
});
module.exports = data;